import React from "react";
import "../../styles/ui/Spinner.scss";

export const Spinner = () => (
  <div className="spinner-overlay">
    <div className="loading-spinner">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);
